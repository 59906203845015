<template>
  <svg
    class="knowledgeable-svg-icon"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 21 21"
    style="enable-background: new 0 0 21 21"
    xml:space="preserve"
  >
    <g>
      <path
        class="st0"
        d="M10.5,19.6c-5,0-9.1-4.1-9.1-9.1s4.1-9.1,9.1-9.1s9.1,4.1,9.1,9.1S15.5,19.6,10.5,19.6z M10.5,2.1
		c-4.6,0-8.4,3.8-8.4,8.4c0,4.6,3.8,8.4,8.4,8.4c4.6,0,8.4-3.8,8.4-8.4C18.9,5.9,15.1,2.1,10.5,2.1z M10.5,6.5
		c-0.2,0-0.3-0.1-0.3-0.3s0.1-0.3,0.3-0.3h0c0.2,0,0.3,0.1,0.3,0.3S10.7,6.5,10.5,6.5z"
      />
    </g>
    <g>
      <path
        class="st0"
        d="M12.2,14.3H8.8c-0.2,0-0.3-0.1-0.3-0.3s0.1-0.3,0.3-0.3h1.4V10H8.8c-0.2,0-0.3-0.1-0.3-0.3s0.1-0.3,0.3-0.3
		h1.8c0.2,0,0.3,0.1,0.3,0.3v4h1.4c0.2,0,0.3,0.1,0.3,0.3S12.4,14.3,12.2,14.3z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'KnowledgeableIcon',
};
</script>

<style scoped>
.knowledgeable-svg-icon .stroke,
.knowledgeable-svg-icon.stroke {
  stroke: currentColor;
  stroke-width: 1;
}
.knowledgeable-svg-icon .fill,
.knowledgeable-svg-icon.fill,
.knowledgeable-svg-icon .st0,
.knowledgeable-svg-icon.st0 {
  fill: currentColor;
}
</style>
