<template>
  <v-app>
    <component :user="user" :is="layout">
      <router-view :user="user" :layout.sync="layout" />
    </component>
  </v-app>
</template>

<script>
import * as fb from '@/firebase';
import '@/app.css';

export default {
  name: 'App',
  components: {},
  data: () => ({
    layout: `div`,
    user: null,
    authUser: null,
    isAdmin: false,
    isSubscribed: false,
    claims: {},
    subscriptions: [],
    location: null,
    subscription: {
      items: null,
      product: null,
    },
    maxCodes: 1,
    selectedAccountAccess: null,
    permissions: null,
    onwerPermissions: {
      analytics: {
        read: true,
        write: true,
        update: true,
      },
      qrCodes: {
        read: true,
        write: true,
        update: true,
      },
      responses: {
        read: true,
        write: true,
        update: true,
      },
      access: {
        read: true,
        write: true,
        update: true,
      },
      survey: {
        read: true,
        write: true,
        update: true,
      },
      emailAccess: {
        receive: true,
      },
    },
  }),
  watch: {
    authUser: {
      immediate: true,
      async handler() {
        if (this.authUser) {
          const uid = fb.auth.currentUser.uid;

          if (!this.authUser.currentLocationOwner) {
            (this.permissions = {
              access: this.onwerPermissions.access,
              qrCodes: this.onwerPermissions.qrCodes,
              responses: this.onwerPermissions.responses,
              analytics: this.onwerPermissions.analytics,
              survey: this.onwerPermissions.survey,
              emailAccess: this.onwerPermissions.emailAccess,
            }),
              await this.$bind(
                'location',
                fb.db
                  .collection('users')
                  .doc(uid)
                  .collection('locations')
                  .doc(this.authUser.currentLocation)
              );
          } else if (this.authUser.currentLocationOwner == uid) {
            (this.permissions = {
              access: this.onwerPermissions.access,
              qrCodes: this.onwerPermissions.qrCodes,
              responses: this.onwerPermissions.responses,
              analytics: this.onwerPermissions.analytics,
              survey: this.onwerPermissions.survey,
              emailAccess: this.onwerPermissions.emailAccess,
            }),
              await this.$bind(
                'location',
                fb.db
                  .collection('users')
                  .doc(uid)
                  .collection('locations')
                  .doc(this.authUser.currentLocation)
              );
          } else {
            await this.$bind(
              'selectedAccountAccess',
              fb.db
                .collection('users')
                .doc(uid)
                .collection('access')
                .doc(this.authUser.currentLocation)
            );
            this.permissions = {
              access: this.selectedAccountAccess.access,
              qrCodes: this.selectedAccountAccess.qrCodes,
              responses: this.selectedAccountAccess.responses,
              analytics: this.selectedAccountAccess.analytics,
              survey: this.selectedAccountAccess.survey,
              emailAccess: this.selectedAccountAccess.emailAccess,
            };
            await this.$bind(
              'location',
              fb.db
                .collection('users')
                .doc(this.authUser.currentLocationOwner)
                .collection('locations')
                .doc(this.authUser.currentLocation)
            );
          }

          await fb.auth.currentUser.getIdTokenResult().then((claims) => {
            let userClaims = claims.claims;

            if (userClaims.admin) {
              this.isAdmin = userClaims.admin;
            } else {
              this.isAdmin = false;
            }

            if (userClaims.subscribed) {
              this.isSubscribed = userClaims.subscribed;
            } else {
              this.isSubscribed = false;
            }

            if (userClaims.active) {
              this.isActive = userClaims.active;
            } else {
              this.isActive = false;
            }
          });

          this.user = {
            isAdmin: this.isAdmin,
            isSubscribed: this.isSubscribed,
            isActive: this.isSubscribed,
            id: fb.auth.currentUser.uid,
            showTip: this.isAdmin ? true : false,
            ...this.authUser,
            ...this.location,
            currentLocationOwner: this.authUser.currentLocationOwner
              ? this.authUser.currentLocationOwner
              : uid,
            permissions: this.permissions,
          };
        }
      },
    },
  },
  async created() {
    if (fb.auth.currentUser) {
      await this.$bind(
        'authUser',
        fb.db.collection('users').doc(fb.auth.currentUser.uid)
      );
    }
  },
};
</script>
