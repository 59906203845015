<template>
  <svg
    class="atmosphere-svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 40 40"
  >
    <path
      class="fill"
      d="M20 0C8.95418 0 0 8.95418 0 20c0 11.0458 8.95418 20 20 20 11.0458 0 20-8.9542 20-20C40 8.95418 31.0458 0 20 0Zm0 38.6239C9.73442 38.6239 1.37608 30.2683 1.37608 20 1.37608 9.73442 9.73442 1.37608 20 1.37608c10.2683 0 18.6253 8.35558 18.6253 18.62252 0 10.2684-8.357 18.6253-18.6253 18.6253Z"
    />
    <ellipse cx="12.0969" cy="14.9563" class="fill" rx="1.04261" ry="3.47536" />
    <ellipse cx="27.903" cy="14.9563" class="fill" rx="1.04261" ry="3.47536" />
    <path
      class="fill"
      d="M29.4541 24.8923c0 2.3412-4.2328 4.4312-9.4541 4.4312-5.2214 0-9.4541-2.09-9.4541-4.4312 0 0 4.2327 3.0224 9.4541 3.0224 5.2213 0 9.4541-3.0224 9.4541-3.0224Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'AtmosphereIcon',
};
</script>

<style scoped>
.atmosphere-svg-icon .stroke,
.atmosphere-svg-icon.stroke {
  stroke: currentColor;
  stroke-width: 1;
}
.atmosphere-svg-icon .fill,
.atmosphere-svg-icon.fill {
  fill: currentColor;
}
</style>
