<template>
  <svg
    class="other-svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 42 42"
  >
    <path
      class="stroke"
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M21 41c11.0457 0 20-8.9543 20-20S32.0457 1 21 1 1 9.9543 1 21s8.9543 20 20 20Z"
    />
    <circle cx="11.7885" cy="21.0002" r="1.77972" class="fill" />
    <circle cx="20.9998" cy="21.0002" r="1.77972" class="fill" />
    <circle cx="30.2111" cy="21.0002" r="1.77972" class="fill" />
  </svg>
</template>

<script>
export default {
  name: 'OtherIcon',
};
</script>

<style scoped>
.other-svg-icon {
  font-weight: 500;
}
.other-svg-icon .stroke,
.other-svg-icon.stroke {
  stroke: currentColor;
  stroke-width: 1;
}
.other-svg-icon .fill,
.other-svg-icon.fill {
  fill: currentColor;
}
</style>
