<template>
  <svg
    class="professionalism-svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 33 40"
  >
    <path
      class="stroke"
      d="M21.8842 5.89993c0 1.56982-.6406 3.58604-1.6862 5.20937-1.0521 1.6334-2.4582 2.7873-3.9508 2.7873s-2.8987-1.1539-3.9508-2.7873c-1.0456-1.62333-1.6861-3.63955-1.6861-5.20937 0-3.11321 2.5237-5.636959 5.6369-5.636959s5.637 2.523749 5.637 5.636959Z"
    />
    <mask id="a" fill="none" stroke-width="1" class="stroke">
      <path
        fill-rule="evenodd"
        d="M6.6245 16.41h19.2443c1.2937 0 2.4011.9279 2.6275 2.2016l1.7779 10.0019c.0223.1255.1314.217.2589.217.1637 0 .2876-.1479.2589-.309l-1.7778-10.0019c-.2711-1.5248-1.5967-2.6356-3.1454-2.6356H6.6245c-1.54868 0-2.87432 1.1108-3.14535 2.6356L1.70127 28.5215c-.02864.1611.09527.309.25892.309.12748 0 .2366-.0915.25891-.217l1.77787-10.0019C4.22339 17.3379 5.33078 16.41 6.6245 16.41Z"
        clip-rule="evenodd"
      />
    </mask>
    <path
      class="fill"
      stroke-width="1"
      d="m28.4963 18.6116-.3931.0699.3931-.0699Zm.5179-.092-.3932.0699.3932-.0699Zm-25.53505 0-.39317-.0699.39317.0699Zm.51782.092.39317.0699-.39317-.0699ZM2.2191 28.6135l-.39317-.0699.39317.0699Zm28.5729-.092-.3931.0699.3931-.0699Zm-.5178.092-.3932.0699.3932-.0699Zm-4.4054-12.6029H6.6245v.7987h19.2443v-.7987Zm3.0207 2.5311c-.2603-1.4643-1.5334-2.5311-3.0207-2.5311v.7987c1.1001 0 2.0418.7891 2.2344 1.8722l.7863-.1398Zm1.7779 10.0019-1.7779-10.0019-.7863.1398 1.7778 10.0019.7864-.1398Zm.5178-.092-1.7779-10.0019-.7863.1398 1.7779 10.0019.7863-.1398Zm-1.7779-10.0019c-.3049-1.7154-1.7962-2.965-3.5385-2.965v.7987c1.3551 0 2.515.9719 2.7522 2.3061l.7863-.1398Zm-3.5385-2.965H6.6245v.7987h19.2443v-.7987Zm-19.2443 0c-1.74226 0-3.23361 1.2496-3.53852 2.965l.78634.1398c.23715-1.3342 1.39708-2.3061 2.75218-2.3061v-.7987Zm-3.53852 2.965L1.30811 28.4516l.78633.1398 1.77788-10.0019-.78634-.1398Zm.51782.092L1.82593 28.5436l.78634.1398 1.77787-10.0019-.78634-.1398Zm3.0207-2.5311c-1.4873 0-2.7604 1.0668-3.0207 2.5311l.78634.1398c.19254-1.0831 1.13423-1.8722 2.23436-1.8722v-.7987ZM1.96019 29.2298c.32106 0 .59589-.2303.65208-.5464l-.78634-.1398c.01157-.0651.06815-.1125.13426-.1125v.7987Zm-.65208-.7782c-.07214.4058.23992.7782.65208.7782v-.7987c.08485 0 .14911.0767.13425.1603l-.78633-.1398Zm29.22499.7782c.4122 0 .7242-.3724.6521-.7782l-.7863.1398c-.0149-.0836.0494-.1603.1342-.1603v.7987Zm-.6521-.5464c.0562.3161.3311.5464.6521.5464v-.7987c.0661 0 .1227.0474.1343.1125l-.7864.1398Z"
      mask="url(#a)"
    />
    <path
      class="stroke"
      d="m5.91127 33.6249-.84249-2.5929c-.12265-.3775-.6567-.3775-.77935 0l-.84249 2.5929H.720618c-.396919 0-.561946.5079-.240834.7412l2.205646 1.6025-.84248 2.5929c-.12266.3775.3094.6914.63051.4581l2.20564-1.6025 2.20565 1.6025c.32111.2333.75317-.0806.63051-.4581l-.84248-2.5929 2.20565-1.6025c.32111-.2333.15608-.7412-.24084-.7412H5.91127Zm12.11593-3.1967-1.3114-4.0362c-.1476-.4543-.7903-.4543-.938 0l-1.3114 4.0362h-4.244c-.47764 0-.67625.6113-.2898.892l3.4334 2.4946-1.3114 4.0362c-.1476.4543.3723.8321.7588.5513l3.4334-2.4945 3.4334 2.4945c.3865.2808.9064-.097.7588-.5513l-1.3114-4.0362 3.4334-2.4946c.3865-.2807.1879-.892-.2898-.892h-4.244Zm11.0203 3.1967-.8425-2.5929c-.1226-.3775-.6567-.3775-.7793 0l-.8425 2.5929h-2.7264c-.3969 0-.5619.5079-.2408.7412l2.2057 1.6025-.8425 2.5929c-.1227.3775.3094.6914.6305.4581l2.2056-1.6025 2.2057 1.6025c.3211.2333.7531-.0806.6305-.4581l-.8425-2.5929 2.2057-1.6025c.3211-.2333.156-.7412-.2409-.7412h-2.7263Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'ProfessionalismIcon',
};
</script>

<style scoped>
.professionalism-svg-icon .stroke,
.professionalism-svg-icon.stroke {
  stroke: currentColor;
  stroke-width: 1;
}
.professionalism-svg-icon .fill,
.professionalism-svg-icon.fill {
  fill: currentColor;
}
</style>
