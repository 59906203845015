<template>
  <svg
    class="staff-svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 25 40"
  >
    <rect width="24.2" height="22" x=".3" y="17.7" class="stroke" rx="1.3" />
    <rect width="13.5" height="15.4" x="5.6" y=".3" class="stroke" rx="1.3" />
    <path
      class="stroke"
      d="M6.9.3h11c.6 0 1.2.5 1.2 1.2v4.6H5.6V1.5C5.6.8 6.2.2 6.9.2Z"
    />
    <path
      class="stroke"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width=".5"
      d="M19.4 22.4v-.2a2 2 0 0 0-3-2.1l-7 3.9a2 2 0 0 1-2.9-1v0c-.6-1.8 1.1-3.4 2.8-2.6l7.2 3.5a2 2 0 0 0 2.9-1.5Z"
    />
    <circle r="1" class="fill" transform="matrix(1 0 0 -1 12.3 26.2)" />
    <circle r="1" class="fill" transform="matrix(1 0 0 -1 12.3 29.8)" />
    <circle r="1" class="fill" transform="matrix(1 0 0 -1 12.3 33.4)" />
  </svg>
</template>

<script>
export default {
  name: 'StaffIcon',
};
</script>

<style scoped>
.staff-svg-icon .stroke,
.staff-svg-icon.stroke {
  stroke: currentColor;
  stroke-width: 1;
}
.staff-svg-icon .fill,
.staff-svg-icon.fill {
  fill: currentColor;
}
</style>
