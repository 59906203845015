<template>
  <svg
    class="food-svg-icon stroke"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 32 40"
  >
    <path
      class="fill stroke"
      fill-rule="evenodd"
      d="M.25.00012207c-.138071 0-.25.11192893-.25.24999993V10.8523c0 4.8308 3.91609 8.7469 8.74683 8.7469 4.83077 0 8.74687-3.9161 8.74687-8.7469V.250122c0-.138072-.112-.24999993-.25-.24999993-.1381 0-.25.11192793-.25.24999993V10.8523c0 4.5546-3.6923 8.2469-8.24687 8.2469C4.19223 19.0992.5 15.4069.5 10.8523V.250122C.5.112051.388071.00012207.25.00012207Z"
      clip-rule="evenodd"
    />
    <rect
      width="1.42183"
      height="39.9996"
      x="8.03601"
      y=".00036621"
      class="fill stroke"
      rx=".710917"
    />
    <rect
      width="1.42183"
      height="39.9996"
      x="30.5312"
      y=".00036621"
      class="fill stroke"
      rx=".710917"
    />
    <path
      class="stroke"
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M30.9803 29.2547h-6.8408V6.84335c0-1.2643.4673-2.49885 1.4511-3.29299 1.3863-1.11916 3.4368-2.43347 5.6103-2.83454"
    />
  </svg>
</template>

<script>
export default {
  name: 'FoodIcon',
};
</script>

<style scoped>
.food-svg-icon .stroke,
.food-svg-icon.stroke {
  stroke: currentColor;
  stroke-width: 1;
}
.food-svg-icon .fill,
.food-svg-icon.fill {
  fill: currentColor;
}
</style>
