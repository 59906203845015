import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/functions';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyBKM9HDbRPSJ3gWAhGPouL-yd3ECk9JbFg',
  authDomain: 'gift-of-feedback.firebaseapp.com',
  projectId: 'gift-of-feedback',
  storageBucket: 'gift-of-feedback.appspot.com',
  messagingSenderId: '113458921491',
  appId: '1:113458921491:web:45c81cbb3f23275b96ffe6',
  measurementId: 'G-PNYQXN3QL8',
};

const dev = false;
const devData = false;
const devFunctionsOnly = false;

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const analytics = firebase.analytics();
const functions = firebase.functions();
if (devFunctionsOnly) {
  functions.useEmulator('localhost', 5001);
}
if (dev) {
  db.useEmulator('localhost', 8082);
  auth.useEmulator('http://localhost:9099');
  storage.useEmulator('localhost', 9199);
  functions.useEmulator('localhost', 5001);
}

export { db, auth, storage, analytics, functions, devData };
