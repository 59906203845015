import Vue from 'vue';
import VueRouter from 'vue-router';
import { auth, db } from '@/firebase';
import firebase from 'firebase/app';
import storageHelper from 'storage-helper';

Vue.use(VueRouter);

var userClaims = {};
var firestoreUser = {};
var firestoreUserLocation = {};
var permissions = {
  analytics: {
    read: true,
    write: true,
    update: true,
  },
  qrCodes: {
    read: true,
    write: true,
    update: true,
  },
  responses: {
    read: true,
    write: true,
    update: true,
  },
  access: {
    read: true,
    write: true,
    update: true,
  },
  survey: {
    read: true,
    write: true,
    update: true,
  },
};

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue'),
    meta: {
      protected: true,
    },
  },

  {
    path: '/protected',
    name: 'Protected',
    component: () => import('@/views/Protected.vue'),
    beforeEnter: async (to, from, next) => {
      if (process.env.VUE_APP_ISPW !== 'T') {
        next('/');
      } else {
        next();
      }
    },
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/AboutView.vue'),
    meta: {
      protected: true,
    },
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: () => import('@/views/PricingView.vue'),
    meta: {
      protected: true,
    },
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('@/views/ProductView.vue'),
    meta: {
      protected: true,
    },
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/ContactView.vue'),
    meta: {
      protected: true,
    },
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import('@/views/BlogView.vue'),
    meta: {
      protected: true,
    },
  },
  {
    path: '/blog/:postLink',
    name: 'post',
    component: () => import('@/views/PostView.vue'),
    meta: {
      protected: true,
    },
  },
  {
    path: '/privacy-policy',
    name: 'privacy',
    component: () => import('@/views/PrivacyView.vue'),
    meta: {
      protected: true,
    },
  },
  {
    path: '/terms-of-use',
    name: 'terms',
    component: () => import('@/views/TermsView.vue'),
    meta: {
      protected: true,
    },
  },
  {
    path: '/cookie-policy',
    name: 'cookiePolicy',
    component: () => import('@/views/CookiePolicyView.vue'),
    meta: {
      protected: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then((user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });
      if (!auth.currentUser) {
        next();
      } else if (auth.currentUser && firestoreUserLocation.active) {
        next('/responses');
      } else if (auth.currentUser && !firestoreUserLocation.active) {
        next('/subscribe');
      } else {
        next('/');
      }
    },
    meta: {
      protected: true,
    },
  },
  {
    path: '/sign-up',
    name: 'signUp',
    component: () => import('@/views/SignUpView.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then((user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next();
      } else if (auth.currentUser && firestoreUserLocation.active) {
        next('/responses');
      } else if (auth.currentUser && !firestoreUserLocation.active) {
        next('/subscribe');
      } else {
        next();
      }
    },
    meta: {
      protected: true,
    },
  },
  {
    path: '/subscribe',
    name: 'subscribe',
    component: () => import('@/views/SubscribeView.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then((user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });
      if (!auth.currentUser) {
        next('/sign-up');
      } else if (auth.currentUser.uid != firestoreUserLocation.ownerId) {
        next('/settings');
      } else if (auth.currentUser && firestoreUserLocation.active) {
        next('/responses');
      } else if (auth.currentUser.uid == firestoreUserLocation.ownerId) {
        next();
      } else if (auth.currentUser && !firestoreUserLocation.active) {
        next();
      } else {
        next('/');
      }
    },
    meta: {
      protected: true,
    },
  },
  {
    path: '/reset-thank-you',
    name: 'ResetPasswordThankYou',
    component: () => import('@/views/ResetPasswordThankYou.vue'),
    beforeEnter: async (to, from, next) => {
      if (!auth.currentUser) {
        next();
      } else {
        next('/responses');
      }
    },
    meta: {
      protected: true,
    },
  },
  {
    path: '/password-reset',
    name: 'ResetPassword',
    component: () => import('@/views/ResetPasswordView.vue'),
    meta: {
      protected: true,
    },
  },
  {
    path: '/unsubscribe',
    name: 'UnsubscribeView',
    component: () => import('@/views/UnsubscribeView.vue'),
    meta: {
      protected: true,
    },
  },
  {
    path: '/success',
    name: 'successView',
    component: () => import('@/views/SuccessView.vue'),
    beforeEnter: async (to, from, next) => {
      if (!auth.currentUser) {
        next('/login');
      } else {
        document.title = `Success | ${process.env.VUE_APP_TITLE}`;
        const _desc = `Success | ${process.env.VUE_APP_TITLE}`;
        document
          .querySelector('meta[name="description"]')
          .setAttribute('content', _desc);

        next();
      }
    },
    meta: {
      protected: true,
    },
  },
  {
    path: '/canceled',
    name: 'canceledView',
    component: () => import('@/views/CanceledView.vue'),
    beforeEnter: async (to, from, next) => {
      if (!auth.currentUser) {
        next('/login');
      } else {
        document.title = `Canceled | ${process.env.VUE_APP_TITLE}`;
        const _desc = `Canceled | ${process.env.VUE_APP_TITLE}`;
        document
          .querySelector('meta[name="description"]')
          .setAttribute('content', _desc);

        next();
      }
    },
    meta: {
      protected: true,
    },
  },
  {
    path: '/responses',
    name: 'Responses',
    component: () => import('@/views/InboxView.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then((user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (!permissions.responses.read) {
        next('/settings');
      } else if (auth.currentUser && firestoreUserLocation.active) {
        next();
      } else if (auth.currentUser && !firestoreUserLocation.active) {
        next('/subscribe');
      } else {
        next('/');
      }
    },
    meta: {
      protected: true,
    },
  },
  {
    path: '/analytics',
    name: 'Analytics',
    component: () => import('@/views/AnalyticsView.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then((user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (!permissions.analytics.read) {
        next('/settings');
      } else if (auth.currentUser && firestoreUserLocation.active) {
        next();
      } else if (auth.currentUser && !firestoreUserLocation.active) {
        next('/subscribe');
      } else {
        next('/');
      }
    },
    meta: {
      protected: true,
    },
  },
  {
    path: '/qr-codes',
    name: 'QrCodes',
    component: () => import('@/views/QrCodesView.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then((user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (!permissions.qrCodes.read) {
        next('/settings');
      } else if (auth.currentUser && firestoreUserLocation.active) {
        next();
      } else if (auth.currentUser && !firestoreUserLocation.active) {
        next('/subscribe');
      } else {
        next('/');
      }
    },
    meta: {
      protected: true,
    },
  },
  {
    path: '/manage-access',
    name: 'ManageAccess',
    component: () => import('@/views/ManageAccess.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then((user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (!permissions.access.read) {
        next('/settings');
      } else if (auth.currentUser && firestoreUserLocation.active) {
        next();
      } else if (auth.currentUser && !firestoreUserLocation.active) {
        next();
      } else {
        next('/');
      }
    },
    meta: {
      protected: true,
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('@/views/SettingsView.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then((user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (auth.currentUser && firestoreUserLocation.active) {
        next();
      } else if (auth.currentUser && !firestoreUserLocation.active) {
        next();
      } else {
        next('/');
      }
    },
    meta: {
      protected: true,
    },
  },
  {
    path: '/admin/dashboard',
    name: 'AdminDashboard',
    component: () => import('@/views/admin/AdminView.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then((user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (userClaims.admin) {
        next();
      } else {
        next('/');
      }
    },
    meta: {
      protected: true,
    },
  },
  {
    path: '/admin/secret/locations',
    name: 'SecretLocations',
    component: () => import('@/views/admin/secret/AllLocations.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then((user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (userClaims.admin) {
        next();
      } else {
        next('/');
      }
    },
    meta: {
      protected: true,
      secret: true
    },
  },
  {
    path: '/admin/users',
    name: 'UsersAdminView',
    component: () => import('@/views/admin/UsersView.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then((user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (userClaims.admin) {
        next();
      } else {
        next('/');
      }
    },
    meta: {
      protected: true,
    },
  },
  {
    path: '/admin/blog',
    name: 'PostsAdminView',
    component: () => import('@/views/admin/PostsView.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then((user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (userClaims.admin) {
        next();
      } else {
        next('/');
      }
    },
    meta: {
      protected: true,
    },
  },
  {
    path: '/admin/blog/edit/:postLink',
    name: 'EditPostAdminView',
    component: () => import('@/views/admin/EditPostView.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then((user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (userClaims.admin) {
        next();
      } else {
        next('/');
      }
    },
    meta: {
      protected: true,
    },
  },
  {
    path: '/admin/pages/edit/:pageId',
    name: 'EditPageView',
    component: () => import('@/views/admin/EditPageView.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then((user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (userClaims.admin) {
        next();
      } else {
        next('/');
      }
    },
    meta: {
      protected: true,
    },
  },
  {
    path: '/:userId/:businessPath/:codeId',
    name: 'ReviewView',
    props: true,
    component: () => import('@/views/ReviewView.vue'),
    beforeEnter: async (to, from, next) => {
      next();
    },
    meta: {
      protected: true,
    },
  },
  {
    path: '/survey/:customUrl',
    name: 'DynamicRedirect',
    props: true,
    component: () => import('@/views/DynamicRedirect.vue'),
    beforeEnter: async (to, from, next) => {
      next();
    },
    meta: {
      protected: true,
    },
  },

  {
    path: '*',
    name: '404',
    component: () => import('@/views/NotFound.vue'),
    meta: {
      protected: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.secret) {
    if (!storageHelper.getItem('user-password-dev')) {
      next('/protected');
    } else if (
      storageHelper.getItem('user-password-dev') !== process.env.VUE_APP_PW2
    ) {
      next('/protected');
    } else next();
  } else if ((to.meta.protected && process.env.VUE_APP_ISPW == 'T')) {
    if (!storageHelper.getItem('user-password')) {
      next('/protected');
    } else if (
      storageHelper.getItem('user-password') !== process.env.VUE_APP_PW
    ) {
      next('/protected');
    } else next();
  } else next();
});

firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const userData = await db.collection('users').doc(user.uid).get();
        firestoreUser = userData.data();
        const locationOwnerId = firestoreUser.currentLocationOwner
          ? firestoreUser.currentLocationOwner
          : user.uid;

        const currentLocation = await db
          .collection('users')
          .doc(locationOwnerId)
          .collection('locations')
          .doc(firestoreUser.currentLocation)
          .get();
        if (firestoreUser.currentLocationOwner) {
          if (firestoreUser.currentLocationOwner != auth.currentUser.uid) {
            await db
              .collection('users')
              .doc(auth.currentUser.uid)
              .collection('access')
              .doc(firestoreUser.currentLocation)
              .get()
              .then((snap) => {
                const data = snap.data();
                permissions = {
                  access: data.access,
                  qrCodes: data.qrCodes,
                  responses: data.responses,
                  analytics: data.analytics,
                  survey: data.survey,
                };
              });
          }
        }

        firestoreUserLocation = currentLocation.data();
        // console.log('permissions', permissions);
      }
      unsubscribe();
      resolve(user);
    }, reject);
  });
};

export default router;
