import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import ServiceIcon from '@/icons/ServiceIcon';
import ProfessionalismIcon from '@/icons/ProfessionalismIcon';
import OtherIcon from '@/icons/OtherIcon';
import AtmosphereIcon from '@/icons/AtmosphereIcon';
import CleanlinessIcon from '@/icons/CleanlinessIcon';
import FoodIcon from '@/icons/FoodIcon';
import SelectionIcon from '@/icons/SelectionIcon';
import StaffIcon from '@/icons/StaffIcon';
import ValueIcon from '@/icons/ValueIcon';
import WaitIcon from '@/icons/WaitIcon';
import ResponsivenessIcon from '@/icons/ResponsivenessIcon';
import KnowledgeableIcon from '@/icons/KnowledgeableIcon';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    values: {
      knowledgeable: {
        component: KnowledgeableIcon,
        props: {
          name: 'KnowledgeableIcon',
        },
      },
      service: {
        component: ServiceIcon,
        props: {
          name: 'ServiceIcon',
        },
      },
      other: {
        component: OtherIcon,
        props: {
          name: 'OtherIcon',
        },
      },
      professionalism: {
        component: ProfessionalismIcon,
        props: {
          name: 'ProfessionalismIcon',
        },
      },
      atmosphere: {
        component: AtmosphereIcon,
        props: {
          name: 'AtmosphereIcon',
        },
      },
      cleanliness: {
        component: CleanlinessIcon,
        props: {
          name: 'CleanlinessIcon',
        },
      },
      food: {
        component: FoodIcon,
        props: {
          name: 'FoodIcon',
        },
      },
      selection: {
        component: SelectionIcon,
        props: {
          name: 'SelectionIcon',
        },
      },
      staff: {
        component: StaffIcon,
        props: {
          name: 'StaffIcon',
        },
      },
      value: {
        component: ValueIcon,
        props: {
          name: 'ValueIcon',
        },
      },
      wait_time: {
        component: WaitIcon,
        props: {
          name: 'WaitIcon',
        },
      },
      responsiveness: {
        component: ResponsivenessIcon,
        props: {
          name: 'ResponsivenessIcon',
        },
      },
    },
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#03A65A',
        secondary: '#014040',
        tertiary: '#FFE188',
        heading: '#014040',
        body: '#014040',
        tan: '#F2F1EB',
        background: '#FAF9F6',
        accent: '#79d0e8',
        orange: '#F26430',
        error: '#A61803',
        warning: '#F26430',
        info: '#79d0e8',
        red: '#A61803',
        pink: '#D91A82',
        success: '#20D91A',
        green: '#03A65A',
        darkgreen: '#014040',
        menu: '#014040',
        white: '#ffffff',
        offwhite: '#E5E5E5',
        offblack: '#20202E',
        black: '#000000',
        lightbg: '#FDFDFD',
        darkwhite: '#F1F2F3',
        bordercolor: '#e1e2e1',
        sad: '#F2391D',
        happy: '#03A65A',
        neutral: '#F26430',
      },
    },
  },
});
