<template>
  <svg
    class="wait-svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 42 42"
  >
    <path
      class="stroke"
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M21 41c11.0457 0 20-8.9543 20-20S32.0457 1 21 1 1 9.9543 1 21s8.9543 20 20 20Z"
    />
    <path
      class="stroke"
      stroke-linecap="round"
      stroke-linejoin="round"
      d="m27.0001 28.9998-4.828-4.828c-.7502-.75-1.1718-1.7673-1.172-2.828V8.99976"
    />
  </svg>
</template>

<script>
export default {
  name: 'WaitIcon',
};
</script>

<style scoped>
.wait-svg-icon .stroke,
.wait-svg-icon.stroke {
  stroke: currentColor;
  stroke-width: 1;
}
.wait-svg-icon .fill,
.wait-svg-icon.fill {
  fill: currentColor;
}
</style>
