<template>
  <svg
    class="selection-svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 42 41"
  >
    <rect
      width="16.4279"
      height="15.4678"
      x=".8"
      y="24.2078"
      class="stroke"
      rx="1.7"
    />
    <rect
      width="17.5429"
      height="16.516"
      x="23.6571"
      y="23.684"
      class="stroke"
      rx="8.25799"
    />
    <path
      class="stroke"
      d="M29.8787 16.3518 22.0606 1.57685c-.4507-.851668-1.6707-.851668-2.1214 0L12.1211 16.3518c-.4229.7992.1565 1.7612 1.0607 1.7612H28.818c.9042 0 1.4836-.962 1.0607-1.7612Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'SelectionIcon',
};
</script>

<style scoped>
.selection-svg-icon .stroke,
.selection-svg-icon.stroke {
  stroke: currentColor;
  stroke-width: 1;
}
.selection-svg-icon .fill,
.selection-svg-icon.fill {
  fill: currentColor;
}
</style>
