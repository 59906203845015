<template>
  <svg
    class="cleanliness-svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 33 42"
  >
    <path
      class="stroke"
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M10.4388 26.804 8.572 36.9894M28.7889 26.804c-.0135-1.8129-.7417-3.5473-2.0266-4.8264-1.2848-1.279-3.0225-1.9995-4.8354-2.0049h-1.6001V4.69975c.0129-.83465-.2597-1.64867-.7727-2.30718-.513-.65851-1.2356-1.12198-2.0481-1.31361-.523-.111537-1.0643-.104829-1.5843.01963-.5201.12446-1.0058.36352-1.4216.69972-.4158.33621-.7513.76106-.9819 1.24352-.2306.48246-.3505 1.01035-.351 1.54509V19.9727h-1.5488c-1.81828.0054-3.56054.7301-4.84625 2.0159-1.28572 1.2857-2.01042 3.0279-2.01584 4.8462L1.09449 38.3331c-.096683.3075-.119574.6334-.06681.9513.05275.3179.17968.619.37048.8787.1908.2597.44012.4708.72773.6162.28762.1454.60545.221.92773.2207H30.4404c.3222.0003.6401-.0753.9277-.2207.2876-.1454.5369-.3565.7277-.6162.1908-.2597.3177-.5608.3705-.8787.0528-.3179.0299-.6438-.0668-.9513L28.7889 26.804Zm-24.08387 0H28.7889 4.70503ZM16.747 36.9894V26.804v10.1854Zm6.3082-10.1854 1.8668 10.1854-1.8668-10.1854Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'CleanlinessIcon',
};
</script>

<style scoped>
.cleanliness-svg-icon .stroke,
.cleanliness-svg-icon.stroke {
  stroke: currentColor;
  stroke-width: 1;
}
.cleanliness-svg-icon .fill,
.cleanliness-svg-icon.fill {
  fill: currentColor;
}
</style>
