<template>
  <svg
    class="value-svg-icon"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 42 42"
  >
    <path
      class="stroke"
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M26.6412 41.0001c7.9303 0 14.3591-6.4288 14.3591-14.3591 0-7.9304-6.4288-14.3591-14.3591-14.3591S12.2821 18.7106 12.2821 26.641c0 7.9303 6.4288 14.3591 14.3591 14.3591Z"
    />
    <path
      class="stroke"
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M23.8033 33.3732c.6246.2811 1.3075.4081 1.9913.3704h2.1547c.952 0 1.8651-.3782 2.5383-1.0514.6732-.6732 1.0514-1.5863 1.0514-2.5383 0-.9521-.3782-1.8652-1.0514-2.5384-.6732-.6732-1.5863-1.0514-2.5383-1.0514h-2.3341c-.9521 0-1.8652-.3782-2.5384-1.0514-.6732-.6732-1.0514-1.5863-1.0514-2.5384 0-.952.3782-1.8651 1.0514-2.5383.6732-.6732 1.5863-1.0514 2.5384-1.0514h2.1546c.6882-.0782 1.3847.0306 2.0163.3148.6317.2842 1.175.7334 1.5729 1.3003m-4.6671-3.5892v17.9483M12.6307 22.7592c.0986.2628.1174.0649.1174.0649m4.0285-6.8741h-2.334c-.9521 0-1.8652-.3782-2.5384-1.0514-.6732-.6732-1.0514-1.5863-1.0514-2.5384 0-.952.3782-1.8651 1.0514-2.53831.6732-.67322 1.5863-1.05142 2.5384-1.05142h2.1546c.6882-.07813 1.3847.03064 2.0163.31486.6316.28423 1.175.7334 1.5729 1.30027m-4.6671-3.58921V17.3296"
    />
    <path
      class="stroke"
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M12.3729 29.4042c-2.52781-.5374-4.8637-1.7471-6.76131-3.5014-1.8976-1.7543-3.28659-3.9882-4.02046-6.4661-.733866-2.4779-.785404-5.1079-.14918-7.6127.63623-2.50472 1.93663-4.79133 3.76405-6.61864 1.82743-1.82731 4.11413-3.12757 6.6189-3.76363 2.5048-.63607 5.1348-.584364 7.6127.14966 2.4778.73402 4.7116 2.12315 6.4658 4.02087 1.7542 1.89772 2.9637 4.23369 3.501 6.76154"
    />
  </svg>
</template>

<script>
export default {
  name: 'ValueIcon',
};
</script>

<style scoped>
.value-svg-icon .stroke,
.value-svg-icon.stroke {
  stroke: currentColor;
  stroke-width: 1;
}
.value-svg-icon .fill,
.value-svg-icon.fill {
  fill: currentColor;
}
</style>
